import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ManageBooks from './pages/ManageBooks';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import Addbook from './pages/Addbook';
import Editbook from './pages/Editbook';
import Subscribers from './components/Subscribers';
import ProtectedRoute from './components/ProtectedRoute';
import UpdateAboutSec from './components/UpdateAboutSec';
import ManageLectures from './pages/ManageLectures';
import AddLecture from './pages/AddLecture';
import EditLecture from './pages/EditLecture';
import ChangePassword from './components/ChangePassword';

function App() {
  return (
    <Router>
    <div className='flex flex-col sm:flex-row '>
      <Sidebar />
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route  path="/change-password" element={<ChangePassword />} />
          <Route path="/edit-book/:id" element={<ProtectedRoute > <Editbook /> </ProtectedRoute>} />
          <Route path="/edit-lecture/:id" element={<ProtectedRoute > <EditLecture /> </ProtectedRoute>} />
        
        <Route path="/manage-books" element={<ProtectedRoute> <ManageBooks /> </ProtectedRoute> } />
        <Route path="/subscribers" element={<ProtectedRoute> <Subscribers /> </ProtectedRoute>} />
        <Route path="/add-book" element={<ProtectedRoute> <Addbook />  </ProtectedRoute>} />
        <Route path="/add-lecture" element={<ProtectedRoute> <AddLecture />  </ProtectedRoute>} />
        <Route path="/update-about" element={<ProtectedRoute> <UpdateAboutSec/> </ProtectedRoute> } />
        <Route path="/manage-lectures" element={<ProtectedRoute> <ManageLectures/> </ProtectedRoute> } />
      </Routes>
    </div>
  </Router>
  
  );
}

export default App;
